import styled from "styled-components"
import { colors } from "../assets/colors"
import Titulo from "./global/titulo"
import ApiInstance from "../services/main.service"
import { useState } from "react"

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
`
const Card = styled.div`
    background-color: ${colors.branco};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 300px;
    width: 100%;
    margin-bottom: 100px;
    padding: 20px 0 ;
    border: 1px solid ${colors.azul};
    max-width: 1200px;

    @media (max-width: 768px) {
        max-width: 90vw;
        margin-bottom: 50px;
    }
    `

const BackgroundImg = styled.img`
    position: absolute;
    opacity: .3;
    top: -150px;
    right: 50%;
    transform: translateX(-50%);
    bottom: 0;
    top: 0;
    rotate: 180deg;
    width: 800px;
    z-index: 5;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    z-index: 55;

`
const Fields = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
`
const Input = styled.input`
    border-radius: 15px;
    padding: 15px;
    font-size: 18px;
`

const Button = styled.button`
    cursor: pointer;
    margin: 20px;
    background-color: ${colors.rosa};
    border: 3px solid ${colors.rosa};
    padding: 10px 15px;
    border-radius: 15px;
    color: ${colors.azul};
    font-size: 20px;
    transition: all .3s;
    &:hover {
        background-color: transparent;
    }

    &:disabled {
        background-color: ${colors.rosa};
        color: ${colors.azul};
        border: 3px solid ${colors.rosa};
        cursor: not-allowed;
    }
`


export default function Rsvp() {
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')

    async function submitForm(e) {
        e.preventDefault()
        if (!nome || !email || !telefone) return alert('Por favor, preencha todos os campos.')
        await new ApiInstance().enviarFormulariodeRsvp(nome, email, telefone).then((res) => {
            if (res?.data?.name === nome) {

                alert('Formulário enviado!');
                e.target.disabled = true;
                e.target.innerHTML = 'Presença confirmada!';
            } else {
                alert('Não enviado. Tente novamente')
                console.log(res)
            }
        })
            .catch(err => {
                console.log(err)
                if (err.response.data.message) {
                    return alert(err.response.data.message)
                }
                alert('Erro ao enviar formulário. Por favor, tente novamente.')
            })
    }

    return (
        <Container id='confirme'>
            <Card>

                <BackgroundImg src={require('../assets/main-flowers.png')} />
                <Titulo>Confirme sua presença</Titulo>
                <Form>
                    <Fields>
                        <Input type="text" onChange={(e) => setNome(e.target.value)} value={nome} placeholder="Nome" />
                        <Input type="text" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="E-mail" />
                        <Input type="text" onChange={(e) => setTelefone(e.target.value)} value={telefone} placeholder="Telefone" />
                    </Fields>
                    <Button onClick={submitForm}>Confirmar</Button>
                </Form>
            </Card>
        </Container>
    )
}