import { useRef, useState } from "react"
import styled from "styled-components"
import ApiInstance from "../../services/main.service"

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
    border: 1px solid black;
    width: 600px;
    padding: 10px;
    margin-bottom: 50px;
    max-width: 100vw;
`
const Title = styled.h1`
    text-align: center;
`
const Select = styled.select`
margin: 10px;
padding: 5px;
`
const Option = styled.option``
const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`
const Field = styled.div`
width: 100%;
border-bottom: 1px solid rgba(25,25,25,0.3);
padding: 10px 0;
`
const Label = styled.label`
font-weight: 500;
`
const Input = styled.input`
    padding: 5px 15px;
    width: 100%;
`
const Button = styled.button`
    padding: 10px 20px;
    font-size: 18px;
    `

export function Form() {
    const [selected, setSelected] = useState()

    const nameRef = useRef()
    const valueRef = useRef()
    const linkRef = useRef()
    const imageLinkRef = useRef()
    const phoneRef = useRef()
    const addressRef = useRef()

    async function handleCreateNewGift() {
        await new ApiInstance().criarPresente({
            name: nameRef?.current?.value,
            value: valueRef?.current?.value,
            link: linkRef?.current?.value,
            imageLink: imageLinkRef?.current?.value
        }).then(res => {
            alert('Criado!', res?.response?.data?.name)
            window.location.reload()
        }).catch(error => {
            console.log(error)
            alert('Erro ao criar. Tente novamente')
        })
    }
    async function handleCreateNewHotel() {
        await new ApiInstance().criarHotel({
            name: nameRef.current.value,
            phone: phoneRef.current.value,
            link: linkRef.current.value,
            address: addressRef.current.value
        }).then(res => {
            alert('Criado!', res?.response?.data?.name)
            window.location.reload()
        }).catch(error => {
            console.log(error)
            if (error?.response?.data.message) {
                return alert(error?.response?.data.message)
            }
            alert('Erro ao criar. Tente novamente')
        })
    }

    return (
        <Container>
            <div>

                <Title>Adicionar novo...</Title>
                <Select onChange={e => setSelected(e.target.value)} value={selected}>
                    <Option value="">Selecione uma opção</Option>
                    <Option value="hotel">Hotel</Option>
                    <Option value="presente">Presente</Option>
                </Select>
            </div>

            {selected === 'presente' && (
                <FieldsWrapper>
                    <Field>
                        <Label>Nome: </Label>
                        <Input ref={nameRef} />
                    </Field>
                    <Field>
                        <Label>Valor: </Label>
                        <Input ref={valueRef} />
                    </Field>
                    <Field>
                        <Label>Link (com https://): </Label>
                        <Input ref={linkRef} />
                    </Field>
                    <Field>
                        <Label>Link da Imagem (com https:// e .png, .jpg, .jpeg etc): </Label>
                        <Input ref={imageLinkRef} />
                    </Field>
                    <Button onClick={handleCreateNewGift}>Adicionar</Button>
                </FieldsWrapper>
            )}
            {selected === 'hotel' && (
                <FieldsWrapper>
                    <Field>
                        <Label>Nome: </Label>
                        <Input ref={nameRef} />
                    </Field>
                    <Field>
                        <Label>Telefone: </Label>
                        <Input ref={phoneRef} />
                    </Field>
                    <Field>
                        <Label>Link (com https://): </Label>
                        <Input ref={linkRef} />
                    </Field>
                    <Field>
                        <Label>Endereço</Label>
                        <Input ref={addressRef} />
                    </Field>
                    <Button onClick={handleCreateNewHotel}>Adicionar</Button>
                </FieldsWrapper>
            )}

        </Container>)

}