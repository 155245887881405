import { useEffect, useState } from "react"
import ApiInstance from '../../services/main.service'
import { BotaoAction, Container, Item, Lista, SectionTitle } from "./components/main"

export default function ConvidadosSection() {
    const [convidados, setConvidados] = useState([])
    const [verMais, setVerMais] = useState(false)

    const filtered = verMais ? convidados : convidados?.slice(0, 2)
    const naoTemConvidadosConfirmados = !convidados || convidados?.length <= 0
    useEffect(() => {
        new ApiInstance().buscarConvidadosConfirmados().then(res => {
            console.log(res.data)
            setConvidados(res.data)
        }).catch(error => {
            console.log(error)
            return alert('Atualize a Página')
        })
    }, [])
    function exportar() {
        if (naoTemConvidadosConfirmados) {
            alert('Lista vazia, aguarde ou recarregue a página!')
        }
        new ApiInstance().exportarParaArquivo('convidados-confirmados.csv', convidados)
    }
    return (
        <Container>
            <SectionTitle>Convidados Confirmados</SectionTitle>
            <Lista>
                {filtered?.map(convidado => (
                    <Item >
                        <label>Nome: </label>
                        <p>{convidado.name}</p>
                        <br />
                        <label>Email: </label>
                        <p>{convidado.email}</p>
                        <br />
                        <label>Telefone: </label>
                        <p>{convidado.phone}</p>
                        <br />
                    </Item>
                ))}
                {convidados.length > 3 && <BotaoAction onClick={() => setVerMais(prev => !prev)}>{verMais ? 'Ver menos' : 'Ver mais'}</BotaoAction>}
                {!naoTemConvidadosConfirmados && <BotaoAction onClick={exportar}>Exportar para CSV</BotaoAction>}
            </Lista>
        </Container >)
}