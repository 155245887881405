// const scheme = process.env.REACT_APP_COLOR_SCHEME

const colors = {
    branco: '#FBFBF9',
    cinza: "#A8B5AE",
    azul: "#45556F",
    amarelo: "#F5C782",
    rosa: "#E2B2A5",
    verde: "#7E8362",
    cinzaOpacity: "rgba(168, 181, 174, 0.8)",
    brancoOpacity: "rgba(251, 251, 249, 0.8)",
}

// switch (scheme) {
//     case 'um':
//         colors = {
//             branco: '#FBFBF9',
//             cinza: "#A8B5AE",
//             azul: "#45556F",
//             amarelo: "#F5C782",
//             rosa: "#7E8362",
//             verde: "#7E8362"
//         }
//         break;
//     case 'dois':
//         colors = {
//             branco: '#FBFBF9',
//             cinza: "#A8B5AE",
//             azul: "#45556F",
//             amarelo: "#F5C782",
//             rosa: "#A8B5AE",
//             verde: "#7E8362"
//         }
//         break;
//     case 'tres':
//         colors = {
//             branco: '#FBFBF9',
//             cinza: "#A8B5AE",
//             azul: "#45556F",
//             amarelo: "#F5C782",
//             rosa: "#FBFBF9",
//             verde: "#7E8362"
//         }
//         break;

//     default:
//         colors = {
//             branco: '#FBFBF9',
//             cinza: "#A8B5AE",
//             azul: "#45556F",
//             amarelo: "#F5C782",
//             rosa: "#E2B2A5",
//             verde: "#7E8362"
//         }
//         break;
// }

export { colors }
