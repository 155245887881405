import styled from 'styled-components'
import Titulo from './global/titulo'
import Texto from './global/texto'
import CardMedio from './global/card-medio'
import foto1 from '../assets/foto2.jpeg'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* background-image: url(${foto1});
    background-repeat: no-repeat;
    background-position: 00px -200px;
    background-size: cover; */
`

const Logo = styled.img`
    width: 100px;
`

const FotoFundo = styled.img`
    position: absolute;
    min-width: 100%;
    width: 1500px;
    top: -200px;
    mask-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
    -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
    mask-composite: intersect;
    -webkit-mask-composite: destination-in;
    opacity: 0.9; 
`

export default function BemVindos() {
    return (
        <Container>
            <FotoFundo src={require('../assets/foto2.jpeg')} />
            <CardMedio>
                <Titulo lineHeight='50px'>Bem-vindos ao nosso site</Titulo>
                <Texto>
                    Estamos emocionados e agradecidos por compartilhar este momento tão especial com vocês.
                    A Villa Erva Docce é o cenário perfeito para um dia inesquecível, e tenham certeza de que cada
                    detalhe foi planejado com muito amor e carinho. No site do casamento, vocês poderão encontrar informações
                    úteis sobre o evento, como o itinerário, dicas de hospedagem, e sugestões de traje, garantindo que todos
                    estejam confortáveis e prontos para celebrar. Esperamos que desfrutem ao máximo da nossa celebração.
                </Texto>
                <Logo src={require('../assets/logo.png')} />
            </CardMedio>
        </Container>)
}