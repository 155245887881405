import styled from "styled-components"
import { colors } from "../../assets/colors"
import detalhe from "../../assets/arabesco1.svg"
const Card = styled.div`
    display: flex;
    position: relative;
    border: 1px solid ${colors.azul};
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 500px;
    border-radius: 15px;
    padding: 40px;
    background-color:${colors.brancoOpacity};
    width: 800px;
    max-width: 90vw;
`
const DetalheTopo = styled.img`
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
`
const DetalheBaixo = styled.img`
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
`

export default function CardMedio({ children }) {
    return (
        <Card>
            <DetalheTopo src={detalhe} />
            <DetalheBaixo src={detalhe} />
            {children}
        </Card>
    )
}