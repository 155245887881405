import { useEffect, useState } from "react"
import ApiInstance from '../../services/main.service'
import { Container, Item, Lista, SectionTitle } from "./components/main"

export default function HoteisSection() {
    const [hoteis, setHoteis] = useState([])

    async function handleRemove(id) {
        if (window.confirm('Deseja deletar?')) {
            const instance = await new ApiInstance()
            await instance.removerHotel(id).then(res => setHoteis(hoteis.filter(item => item?.id !== res?.id)))
        }
    }

    useEffect(() => {
        new ApiInstance().buscarHospedagens().then(res => setHoteis(res.data))
    }, [])

    return (
        <Container>
            <SectionTitle>Lista de Hotéis</SectionTitle>
            <Lista>
                {hoteis?.map(hotel => (
                    <Item>
                        <label>Nome: </label>
                        <p>{hotel.name}</p>
                        <br />
                        <label>Telefone: </label>
                        <p>{hotel.phone}</p>
                        <br />
                        <label>Endereço: </label>
                        <p>{hotel.address}</p>
                        <br />
                        <label>Link: </label>
                        <p>{hotel.link}</p>
                        <br />
                        <button onClick={() => handleRemove(hotel.id)}>Remover</button>
                    </Item>
                ))}
            </Lista>
        </Container>)
}