import styled from "styled-components"
import Pattern from '../assets/pattern.png'
import { colors } from "../assets/colors"

const Footer = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100vw;
    gap: 10px;
    background-color: ${colors.branco};
`
const Logo = styled.img`
    width: 100px;
`

const BorderImageCima = styled.div`
    background-image: url(${Pattern});
    background-size: 150px;
    background-repeat: repeat-x;
    height: 20px;
    width: 100%;
    margin-bottom: 30px;
    z-index: 55;
`
const BorderImageBaixo = styled.img`
    background-image: url(${Pattern});
    background-size: 150px;
    background-repeat: repeat-x;
    height: 20px;
    width: 100%;
    z-index: 55;
    margin-top: 30px;
`

export default function FooterComponent() {
    return (<Footer>
        <BorderImageCima></BorderImageCima>
        <Logo src={require('../assets/logo.png')} />
        Copyright 2025 – Letícia e Marcelo
        <i>Todos os direitos reservados
        </i><BorderImageBaixo />
    </Footer>)
}