import styled from 'styled-components';
import HeaderComponent from './components/header';
import { colors } from './assets/colors';
import Capa from './components/capa';
import BemVindos from './components/bem-vindos';
import Cerimonia from './components/cerimonia';
import Traje from './components/traje';
import Countdown from './components/countdown';
import Hospedagem from './components/hospedagem';
import Rsvp from './components/rsvp';
import Presentes from './components/presentes';
import FooterComponent from './components/footer';


const MainContainer = styled.div`
  width: 100%;
  background-color: ${colors.branco};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  position: relative;
`
function App() {

  document.querySelector('#root').style.backgroundColor = colors.branco;

  return (
    <MainContainer>
      <HeaderComponent />
      <Capa />
      <BemVindos />
      <Cerimonia />
      <Traje />
      <Countdown />
      <Hospedagem />
      <Rsvp />
      <Presentes />
      <FooterComponent />
    </MainContainer>
  );
}

export default App;
