import styled from "styled-components"
import ConvidadosSection from "./components/admin/convidados"
import HoteisSection from "./components/admin/hoteis"
import PresentesSection from "./components/admin/presentes"
import { useEffect, useState } from "react"
import ApiInstance from "./services/main.service"
import { Form } from "./components/admin/form"

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const PageTitle = styled.h1`
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background-color: red;
    color: white;
    margin-bottom: 50px;
`
const Button = styled.button`
    padding: 5px 15px;

    position: ${(props) => props.logout ? 'absolute' : 'static'};
    top: ${(props) => props.logout && '10px'};
    left: ${(props) => props.logout && '50px'};
    
`

const LoginTitle = styled.h2`
`
const Label = styled.label`
    margin-top: 20px;
`
const Input = styled.input`
    padding: 5px 15px;
`
const LoginButton = styled.button`
    margin-top: 20px;
    padding: 5px 20px;
`
export default function Admin() {
    const [user, setUser] = useState()
    const [password, setPassword] = useState()
    const [isLoggedIn, setIsLoggedIn] = useState(false)


    async function handleLogin() {

        if (!user || !password) {
            return alert('Por favor, preencha os campos')
        }
        new ApiInstance().login({ user, password }).then(res => {
            localStorage.setItem('token', res.data.access_token)
            setIsLoggedIn(true)
        }).catch(e => {
            console.log(e)
            alert('Não autorizado.')
        })
    }


    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            setIsLoggedIn(true)
        }
        else {
            setIsLoggedIn(false)
        }
    }, [])
    return (
        <Page>
            {isLoggedIn && <Button logout={true} onClick={() => {
                localStorage.removeItem('token')
                window.location.reload()
            }}>sair</Button>}
            <PageTitle>Admin</PageTitle>

            {isLoggedIn ? (
                <>
                    <Form />
                    <ConvidadosSection />
                    <PresentesSection />
                    <HoteisSection />
                </>
            ) : (
                <>
                    <LoginTitle>Login</LoginTitle>
                    <Label>Nome</Label>
                    <Input onChange={(e) => setUser(e.target.value)} value={user} />
                    <Label>Senha</Label>
                    <Input type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                    <LoginButton onClick={handleLogin}>ENVIAR</LoginButton>
                </>
            )}
        </Page>)
}