import styled from "styled-components";
import Titulo from "./global/titulo";
import { colors } from "../assets/colors";
import CardMedio from "./global/card-medio";
import Texto from "./global/texto";
import { useEffect, useState } from "react";
import ApiInstance from "../services/main.service";

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    position: relative;
    width: 100%;
    overflow: hidden;
    
`

const Cards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid ${colors.cinza};
    gap: 20px;
    
`
const CardReserva = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 150px;
`
const Nome = styled.p`
    font-size: 25px;
`
const Telefone = styled.a`
    display: block;
    color: ${colors.azul};
    text-decoration: none;
    font-size: 20px;
    `
const Endereco = styled.p`
    font-size: 16px;
    `
const Link = styled.a`
    font-size: 16px;
    text-decoration: none;
    color: ${colors.azul};
    background-color: ${colors.rosa};
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    transition: all 0.3s;
    border: 3px solid ${colors.rosa};
    font-weight: 700;
    &:hover {
        background-color: transparent;
    }
`
const FotoFundo = styled.img`
position: absolute;
width: 100%;
top: -800px;
mask-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
-webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
mask-composite: intersect;
-webkit-mask-composite: destination-in;
opacity: 0.9; 
`
export default function Hospedagem() {
    const [hoteis, setHoteis] = useState([])
    useEffect(
        () => {
            new ApiInstance().buscarHospedagens()
                .then(res => setHoteis(res.data))
        }, [])
    return (
        <Container id="hospedagem">
            <FotoFundo src={require('../assets/foto-mao.jpeg')} />

            <CardMedio>
                <Titulo>Hospedagem</Titulo>
                <Texto>Disponibilizaremos <i>transfer</i> para o trajeto entre os hotéis (Crystal Suites, Enzo Hotel e Restaurante, Hotel Palmelo) ida e volta. </Texto>
                <Texto>Seguem contatos para efetuar reservas:</Texto>
                <Cards>

                    {
                        hoteis?.map(item => (
                            <CardReserva >
                                <Nome>{item.name}
                                    <Telefone href={`https://whatsa.me/${item.phone}`} target='_blank'>{item.phone}</Telefone>
                                </Nome>
                                <Endereco>{item.address}</Endereco>
                                <Link href={item.link} target="_blank">Reserva</Link>
                            </CardReserva>
                        )
                        )
                    }
                </Cards>

            </CardMedio>
        </Container >
    )
}