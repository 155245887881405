import styled from "styled-components"
import Titulo from "./global/titulo"
import Texto from "./global/texto"
import CardMedio from "./global/card-medio"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 30px;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 50px 30px;
    }
`
const Descricao = styled.p`
    font-size: 30px;
    z-index: 55;
    max-width: 70vw;

    @media (max-width: 768px) {
        text-align: center;
    }
`

const Wrapper = {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    'align-items': 'center',

    '@media (max-width: 768px)': {
        margin: '20px 0'
    }
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    @media (max-width: 768px) {
        align-items: center;
    }
`


const WrapperM = styled.div`
    ${Wrapper};
    margin-left: 40px;

    @media (max-width: 768px) {
        flex-direction: column
    }
`
const WrapperH = styled.div`
    ${Wrapper};
    margin-right: 40px;
    align-items: center;
    justify-content: end;

    @media (max-width: 768px) {
        margin-top: 50px;
        flex-direction: column
    }
`

const imagens = {
    width: "80px",
    "z-index": "55",
}
const ImageH = styled.img`
    ${imagens};
`
const ImageM = styled.img`
    ${imagens};
    left: 30px;
    top: 70px;

    @media (max-width: 768px) {
        order: 2;
    }
`

export default function Traje() {
    return (
        <Container id="traje">
            <CardMedio>
                <Titulo>Traje
                    <Descricao>Esporte fino</Descricao>
                </Titulo>
                <WrapperM align="start">
                    <ImageM src={require("../assets/traje-m.png")} />
                    <Column>
                        <Descricao>Comprimento Longo ou Midi</Descricao>
                        <Texto textAlign="start">Todas as cores e estampas são bem-vindas.</Texto>
                        <Texto textAlign="start">(exceto tons claros que se assemelhem ao branco/off-white)</Texto>
                        <Texto textAlign="start">Sugerimos o uso de salto bloco para maior conforto.</Texto>
                    </Column>
                </WrapperM>
                <WrapperH align="end">
                    <Column>
                        <Descricao>Blazer clássico e/ou camisa social,</Descricao>
                        <Descricao>calça de alfaiataria</Descricao>
                        <Texto textAlign="end">Todos os tons são bem-vindos.</Texto>
                    </Column>
                    <ImageH src={require("../assets/traje-h.png")} />
                </WrapperH>
            </CardMedio>
        </Container>
    )
}