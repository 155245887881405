import styled from "styled-components";
import { colors } from "../assets/colors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin-bottom: 100px;
`
const ImagemCentral = styled.img`
    width: 800px;
    max-width: 90vw;
`
const NomesWrapper = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    max-width: 90vw;

    @media (max-width: 768px) {
        margin-top: 30px;
        flex-direction: column;
    }
`

const Nome = styled.h1`
color: ${colors.azul};
font-family: 'LuciaBT', cursive;
font-size: 100px;
`
const Texto = styled.p`
color: ${colors.azul};
font-family: 'LuciaBT', cursive;
font-size: 50px;
`

export default function Capa() {
    return (
        <Container id="home">
            <NomesWrapper>
                <Nome>Letícia</Nome>
                <Nome>e</Nome>
                <Nome>Marcelo</Nome>
            </NomesWrapper>
            <ImagemCentral src={require('../assets/main-flowers.png')} />
            <Texto>21.06.2025</Texto>
            <Texto>Vila Erva Docce</Texto>
        </Container>
    );
}