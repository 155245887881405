import Titulo from "./global/titulo";
import styled from "styled-components";
import { colors } from "../assets/colors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    max-width: 1200px;

    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 50px;
`
const BotaoComprar = styled.a`
    background-color: ${colors.rosa};
    color: ${colors.azul};
    padding: 5px 20px;
    border: 3px solid ${colors.rosa};
    transition: all 0.3s;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
        background-color: transparent;
    }
`

export default function Presentes() {

    return (
        <Container id="lista">
            <Titulo>Listas de Presentes</Titulo>
            <Wrapper>
                <BotaoComprar href="https://m.camicado.com.br/lista/convidado/leticiaemarcelo" target="_blank">camicado.com.br</BotaoComprar>
                <BotaoComprar href="https://noivos.casar.com/leticia-e-marcelo-2025-06-21#/presentes" target="_blank">noivos.casar.com</BotaoComprar>
            </Wrapper>

        </Container>
    )
}