
import axios from "axios"

class ApiInstance {
    api = {}

    constructor() {
        const token = localStorage.getItem('token') || ''
        this.api = axios.create({
            baseURL: 'https://casamento-leticia-e-marcelo-backend.vercel.app/',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        this.api.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    console.error('Unauthorized access - possibly invalid token');
                    window.location.reload()
                }
                return Promise.reject(error);
            }
        );
    }


    async login({ user, password }) {
        console.log(user, password)
        return await this.api.post('/login', { user, password })
    }

    async enviarFormulariodeRsvp(nome, email, telefone) {
        return await this.api.post('/confirm-invitation', {
            name: nome,
            phone: telefone,
            email: email
        })
    }

    async buscarHospedagens() {
        return this.api.get('/hotels')
    }

    async buscarConvidadosConfirmados() {
        return await this.api.get('/confirmed')
    }

    async exportarParaArquivo(filename, data) {
        if (!data || data?.length <= 0) {
            console.error('Array vazio ou inválido');
            return;
        }
        const header = Object.keys(data[0]).join(',');
        const rows = data.map(obj =>
            Object.values(obj)
                .map(value => `"${value}"`)
                .join(',')
        );
        const csvContent = [header, ...rows].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async removerHotel(id) {
        return this.api.delete(`/delete-hotel/${id}`);
    }
    async removerPresente(id) {
        return this.api.delete(`/delete-gift/${id}`);
    }
    async criarHotel({ name, phone, address, link }) {
        return this.api.post(`/create-hotel`, {
            name, phone, address, link
        });
    }
    async criarPresente({ name, value, link, imageLink }) {
        return this.api.post(`/create-gift/`, {
            name, value: parseFloat(value), link, imageLink
        });
    }

}

export default ApiInstance