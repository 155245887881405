import { useEffect, useState } from "react"
import ApiInstance from '../../services/main.service'
import { Container, Item, Lista, SectionTitle } from "./components/main"

export default function PresentesSection() {
    const [presentes, setPresentes] = useState([])

    async function handleRemove(id) {
        if (window.confirm('Deseja deletar?')) {
            const instance = new ApiInstance()
            await instance.removerPresente(id).then(res => setPresentes(presentes.filter(item => item?.id !== res?.id)))
        }
    }

    useEffect(() => {
        new ApiInstance().buscarPresentes().then(res => setPresentes(res.data))
    }, [])


    return (
        <Container>
            <SectionTitle>Lista de presentes</SectionTitle>
            <Lista>
                {presentes?.map(presente => (
                    <Item >
                        <label>Nome: </label>
                        <p>{presente.name}</p>
                        <label>Valor: </label>
                        <p>{presente.value}</p>
                        <label>Imagem: </label>
                        <p>{presente.imageLink}</p>
                        <label>Link: </label>
                        <p>{presente.link}</p>
                        <button onClick={() => handleRemove(presente?.id)}>Remover</button>
                    </Item>
                )
                )
                }
            </Lista>
        </Container >)
}