import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 200px;
    border-bottom: 5px solid black;
    flex-wrap: wrap;
`

export const SectionTitle = styled.h2`
    background-color: red;
    color: white;
    text-align: center;
`

export const Lista = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`

export const Item = styled.li`
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    
    @media (max-width: 768px) {
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(100,100,100, .2);
   
    }    
    &:nth-child(2n){
        background-color: rgba(100,100,100, .2);
    }
`

export const BotaoAction = styled.button`
    padding: 5px 20px;
    min-width: 100px;
    width: fit-content;
    font-size: 18px;
    align-self: center;
    margin: 20px 0;
`

export const More = styled.p`
    background-color: yellow;
    font-size: 18px;
    width: 100px;
    text-align: center;
    align-self: flex-start;
`

export const Campo = styled.div`
    display: flex;
    border-right: 1px solid black;
    padding: 10px;
    word-wrap: break-word;
`

export const Input = styled.input`
    min-width: fit-content;
    width: max-content;
`