import styled from "styled-components"
import { colors } from "../../assets/colors"

const Titulo = styled.h1`
    font-family: 'LuciaBT', cursive;
    font-weight: 700;
    font-size: 70px;
    color: ${colors.azul};
    z-index: 55;
    text-align: center;
    line-height: ${props => props.lineHeight ? props.lineHeight : 'auto'};
`

export default Titulo