import styled from "styled-components"
import { colors } from "../assets/colors"
import Titulo from "./global/titulo"
import Texto from "./global/texto"

const Container = styled.div`
    background-color: ${colors.branco};
    border-radius: 15px;
    border: 1px solid ${colors.azul};
    color: ${colors.azul};
    padding: 40px 0; 
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    margin: 100px 0 50px;
    position: relative;

    @media (max-width: 768px) {
        max-width: 90vw;
        margin: 50px 0;
    }
`
const BackgroundImg = styled.img`
    position: absolute;
    z-index: 0;
    opacity: .6;
    right: 0;
    transform: rotateZ(90deg);
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 55;
    width: 100%;
`

const TextWrapper = styled.div`
    margin: 30px 0;
    z-index: 5;
`
const Data = styled.div`
    margin-top: 15px;
    font-size: 30px;
    z-index: 55;
`

const IFrame = styled.iframe`
    z-index: 55;
    max-width: 90%;
`

export default function Cerimonia() {
    return (
        <Container id="cerimonia">
            <BackgroundImg src={require("../assets/main-flowers.png")} />
            <Wrapper>
                <Data>21.06.2025</Data>
                <Titulo>Cerimônia e Festa</Titulo>
                <Data>15h30</Data>
                <TextWrapper>
                    <Texto>Vila Erva Docce</Texto>
                    <Texto>Rodovia GO 020, KM 125, zona rural</Texto>
                    <Texto>Palmelo-GO</Texto>
                </TextWrapper>
                <TextWrapper>
                    <Texto>Gostaríamos de contar com a presença de todos vocês no momento em que nossa união será abençoada
                        diante de Deus! A cerimônia será rápida e tentaremos ser extremamente pontuais. Contamos com vocês!
                        Disponibilizaremos vans saindo dos principais hotéis em Palmelo às 15h15.
                        O retorno da festa com destino aos hotéis será entre 22h e 3h da manhã.
                    </Texto>
                </TextWrapper>
                <Texto>
                </Texto>
                <IFrame
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.7443518857817!2d-48.405799991430285!3d-17.376030346905214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a75fd7f88d808f%3A0x8b9e729312779467!2sVilla%20Erva%20Docce!5e0!3m2!1spt-BR!2sbr!4v1735252660490!5m2!1spt-BR!2sbr" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"                ></IFrame>
                <TextWrapper>
                    <Texto>Contamos com a pontualidade de todos. Cerimônia e festa, ocorrerão no mesmo local.</Texto>
                </TextWrapper>
            </Wrapper>
        </Container >
    );
}


