import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../assets/colors.js';

const Header = styled.header`
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color:${colors.cinzaOpacity};
    position: fixed;
    width: 100%;
    max-width: 1200px;
    border-radius: 15px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-weight: 700;
    @media (max-width: 768px){
       justify-content: space-between;
       background-color: ${(props) => props.isOpen ? colors.cinza : colors.cinzaOpacity};
       
    }
`;

const Logo = styled.img`
    width: 100px;
    /* display: none; */
    
    @media (max-width: 768px){
        display: block;
        order: -1;
        align-self: center;
    }
`;
const Logo2 = styled.img`
    width: 100px;
    max-width: 100px;
    align-self: center;
    flex: 1;
    display: none;
    
    @media (max-width: 768px){
        display: ${(props) => props.isOpen ? 'none' : 'block'};
    }

`;

const Nav = styled.nav`
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        display: ${props => (props.isOpen ? 'block' : 'none')};
    }
`;

const NavList = styled.ul`
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

const NavItem = styled.li`
    margin: 0 1rem;
    display: flex;
    justify-content: center;

`;

const NavLink = styled.a`
    text-decoration: none;
    color: ${colors.azul};
    font-weight: 700;
    transition: all 0.3s;
    padding: 5px 0px;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    &:hover {
        border-bottom-color: ${colors.azul};
}
`;

const MenuButton = styled.button`
    display: none;
    background: none;
    border: none;
    color: ${colors.azul};
    font-size: 1.5rem;

    @media (max-width: 768px) {
        display: block;
    }
`;

const HeaderComponent = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Header isOpen={isOpen}>
            <MenuButton onClick={toggleMenu}>
                ☰
            </MenuButton>
            <Logo2 isOpen={isOpen} src={require('../assets/logo.png')} alt="Logo" />
            <div></div>
            <Nav isOpen={isOpen}>
                <NavList>
                    <NavItem>
                        <NavLink href="#home">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#cerimonia">Cerimônia</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#lista">Lista de presentes</NavLink>
                    </NavItem>
                    <Logo src={require('../assets/logo.png')} alt="Logo" />
                    <NavItem>
                        <NavLink href="#traje">Traje</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#hospedagem">Hospedagem</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#confirme">Confirme sua presença</NavLink>
                    </NavItem>
                </NavList>
            </Nav>
        </Header>
    );
};

export default HeaderComponent;