import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../assets/colors";

const Container = styled.div`
    background-color: ${colors.branco};
    width: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin: 0  0 50px;
    gap: 30px;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 1200px;
    @media (max-width: 768px) {
        max-width: 90vw;
        margin: 50px 0;

    }
`
const Field = styled.div`
    border: 1px solid black;
    padding: 20px 40px;
    border-radius: 15px;
    width: 150px;
    align-items: center;
    display: flex;
    flex-direction: column;
`
const Label = styled.div`
    font-family: "LuciaBT";
    font-size: 30px;
`

const Numero = styled.div`
    font-family: "LuciaBT";
    font-size: 100px;
`
const BackgroundImg = styled.img`
    width: 650px;
    opacity: .3;
    bottom: 55px;
    position: absolute;

    @media (max-width: 768px) {
        display: none;
    }
`

const Countdown = () => {
    const endDate = '2025-06-20T23:59:59'; // um dia antes, 23:59:59
    const padWithZero = (number) => number.toString().padStart(2, "0");

    const calculateTimeLeft = useCallback(() => {
        const now = new Date();
        const difference = new Date(endDate) - now;

        if (difference <= 0) {
            return {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            days: padWithZero(days),
            hours: padWithZero(hours),
            minutes: padWithZero(minutes),
            seconds: padWithZero(seconds),
        };
    }, [])

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft);
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft, endDate]);

    return (
        <Container>
            <BackgroundImg src={require('../assets/main-flowers.png')} />
            <Field>
                <Numero>{timeLeft.days}</Numero>
                <Label>Dias</Label>
            </Field>
            <Field>
                <Numero>{timeLeft.hours}</Numero>
                <Label>Horas</Label>
            </Field>
            <Field>
                <Numero>{timeLeft.minutes}</Numero>
                <Label>Minutos</Label>
            </Field>
            <Field>
                <Numero>{timeLeft.seconds}</Numero>
                <Label>Segundos</Label>
            </Field>
        </Container>
    );
};

export default Countdown;
