import styled from "styled-components"
import { colors } from "../../assets/colors"

const Texto = styled.p`
    z-index: 55;
    font-family: 'Forum', sans-serif;
    width: 600px;
    color: ${colors.azul};
    max-width: 90vw;
    font-size: 20px;
    text-align: ${props => props.textAlign ? props.textAlign : "center"};
    
    @media (max-width: 768px) {
        padding: 0 10px;   
        text-align: center;
    }
`

export default Texto